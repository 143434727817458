
import { defineComponent, ref } from "vue";
import { hideModal, showModal } from "@/core/helpers/dom";
import FormAvalue from "@/components/agenda/FormAvalue.vue";
import FormSale from "./FormSale.vue";
export default defineComponent({
  name: "ModalConfAgenda",
  components: {
    FormAvalue,
    FormSale,
  },
  setup(props, { emit }) {
    const newAgencyModalRef = ref<null | HTMLElement>(null);
    const change = ref<boolean>(true);

    const openModal = () => {
      change.value = !change.value;
      showModal(newAgencyModalRef.value);
    };

    const closeModal = () => {
      hideModal(newAgencyModalRef.value);
    };

    return {
      newAgencyModalRef,
      openModal,
      closeModal,
      change,
    };
  },
});
