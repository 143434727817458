
import { defineComponent, ref, toRefs, watch } from "vue";
import { hideModal, showModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import ModalCitaAtendida from "@/components/citas/CitaAtendida.vue";
import ModalCitaCancelada from "@/components/citas/CitaCancelada.vue";
import moment from "moment";
export default defineComponent({
  name: "citaDetalleVehicle",
  components: {
    Field,
    Form,
    ErrorMessage,
    ModalCitaAtendida,
    ModalCitaCancelada,
  },
  props: {
    detailMeeting: {
      type: Object,
      default: "",
    },
  },
  emits: [],
  setup(props, { emit }) {
    const detalleCitaModalRef = ref<null | HTMLElement>(null);
    const estatus = ref<any>("Pendiente");
    const citaAtendidaModalRef = ref<null | HTMLElement>(null);
    const modalAtendida = ref<boolean>(false);
    const modalCancelada = ref<boolean>(false);
    const showSelect = ref<boolean>(false);
    moment.locale("es-mx");

    const firstCharToUppercase = (text) => {
      return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const changeStatus = () => {
      if (estatus.value == "Atendida") {
        //hideModal(detalleCitaModalRef.value);
        modalAtendida.value = true;
      }
      if (estatus.value == "Cancelada") {
        modalCancelada.value = true;
      }
      if (estatus.value == "Pendiente") {
        showSelect.value = false;
      }
    };

    const showSelected = () => {
      showSelect.value = !showSelect.value;
      if (showSelect.value == false) {
        estatus.value = "Pendiente";
      }
    };

    const closeAtendida = (evt) => {
      modalAtendida.value = evt;
      //showSelect.value = false;
    };

    const closeCancelada = (evt) => {
      modalCancelada.value = evt;
      //showSelect.value = false;
    };

    const openDetailMeeting = () => {
      showModal(detalleCitaModalRef.value);
    };

    const closeDetailMeeting = () => {
      hideModal(detalleCitaModalRef.value);
    };

    function fortmatPrice(data: string) {
      console.log(data);
      return 10;
      //return data;
    }

    return {
      estatus,
      changeStatus,
      detalleCitaModalRef,
      citaAtendidaModalRef,
      modalAtendida,
      modalCancelada,
      showSelect,
      showSelected,
      closeAtendida,
      closeCancelada,
      openDetailMeeting,
      closeDetailMeeting,
      moment,
      fortmatPrice,
      firstCharToUppercase,
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatKm(value) {
      let val = (value / 1).toFixed(0);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " KM";
    },
  },
});
