
import { defineComponent, ref, toRefs, watch } from "vue";
import { hideModal, showModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import loaderClientVue from "@/views/client/components/loader-client.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
interface Schedule {
  id: string | null;
  comment: string;
  status: number;
}
export default defineComponent({
  name: "citaDetalleVehicle",
  components: {
    Field,
    Form,
    ErrorMessage,
    loaderClientVue,
  },
  props: {
    showModal: {
      required: true,
      type: Boolean,
      default: false,
    },
    idschedule: {
      type: String,
      default: "",
    },
  },
  emits: ["closeCancelada", "closeSelect", "updateCalendar"],
  setup(props, { emit }) {
    const detalleCitaCanceladaModalRef = ref<null | HTMLElement>(null);
    const showModalProp = toRefs(props).showModal;
    const loading = ref<boolean>(false);
    const formData = ref<Schedule>({
      id: props.idschedule,
      comment: "",
      status: 3,
    });

    const router = useRouter();

    const closeCancelada = () => {
      emit("closeCancelada", false);
      emit("closeSelect", false);
      formData.value = {
        id: "",
        comment: "",
        status: 3,
      };
    };

    watch(showModalProp, async (current) => {
      if (current) {
        showModal(detalleCitaCanceladaModalRef.value);
      }
    });

    const validation = Yup.object({
      comment: Yup.string()
        .required("El campo comentario es obligatorio")
        .label("Comentario"),
    });

    const sendData = async () => {
      const data = {
        comment: formData.value.comment,
        status_id: formData.value.status,
      };
      await axios
        .put("api/citas/" + props.idschedule, data)
        .then((result) => {
          if (result.data.success) {
            loading.value = false;
            Swal.fire({
              text: "Se actualizó correctamente el estatus de la cita.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Cerrar",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              closeCancelada;
              emit("updateCalendar", 3);
              router.go(0);
            });
          }
        })
        .catch((error) => {
          loading.value = false;
          if (error.success == false) {
            Swal.fire({
              text:
                typeof error.message === "object"
                  ? Object.values(error.errors).toString().replace(",", "</br>")
                  : error.message,
              icon: "error",
            }).then(() => {
              closeCancelada;
            });
          } else {
            Swal.fire({
              text:
                typeof error.response.data.errors === "object"
                  ? Object.values(error.response.data.errors)
                      .toString()
                      .replace(",", "</br>")
                  : error.response.data.message,
              icon: "error",
            }).then(() => {
              closeCancelada;
            });
          }
        });
    };

    return {
      detalleCitaCanceladaModalRef,
      closeCancelada,
      formData,
      sendData,
      validation,
      loading,
    };
  },
});
