
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import FullCalendar, { sliceEvents, CalendarOptions } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import moment from "moment";
import events from "@/core/data/events";
import { TODAY } from "@/core/data/events";
import loaderClientVue from "@/views/client/components/loader-client.vue";
import ModalAgenda from "@/components/agenda/ModalAgenda.vue";
import ModalCreateSchedule from "@/components/agenda/ModalCreateSchedule.vue";
import esLocale from "@fullcalendar/core/locales/es";
import parseHTML from "@/helpers/parseHtml";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import ModalCitaDetalle  from "@/components/citas/CitaDetalle.vue";
import axios from "axios";
import { Field, ErrorMessage } from "vee-validate";
import { useRoute } from 'vue-router';


interface EventsData{
  title:string;
  start:string;
  description:string;
  className:string;
  date:string;
  idmeeting:number
}

export default defineComponent({
  name: "agenda",
  components: {
    FullCalendar,
    ModalAgenda,
    ModalCreateSchedule,
    ModalCitaDetalle,
    loaderClientVue,
    Field,
  },
  emits:['loadmeetings'],
  setup(props, { emit }) {
    const type = ref<string>("new");
    const ModalConfigSchedules = ref<any>(null);
    const ModalCreateSchedules = ref<any>(null);
    const ModalDetailMeetingRef = ref<any>(null);
    const getPermissions:any=localStorage.getItem('permissions_1');
    const permissions = JSON.parse(getPermissions);
    const calendarData=ref<EventsData[]>([]);
    const router = useRouter();
    const calendarOptions = ref<CalendarOptions>({});
    const buttonsCustom=ref<string>("");
    const meetings=ref<any[]>([]);
    const detailmeeting=ref<any[]>([]);
    const tipoCita=ref<any>("Filtrar citas");
    const loading=ref<boolean>(true);
    const type_schedule=ref<any>(1);
    const route=useRoute();
    const idAgency = ref<any>("");
    const change = ref<boolean>(false);


    const types = ref<any>([{ id:"Filtrar citas", name:"Filtrar citas" }, { id:1, name:"Avalúo en agencia"}, { id:2, name:"Venta" }]);

    const openDetail = (info) => {
      ModalDetailMeetingRef.value!.openDetailMeeting();
      const close: any = document.querySelector(".fc-popover-close")
      if ( close ){
        close.click()
        };
      const data:any=info.event;
      const detail:any=meetings.value.filter(m=>m.id==data.extendedProps.idmeeting);
      detail[0]['typeSchedule']=tipoCita.value;
      detailmeeting.value=detail[0];
    };
    
    onMounted(async() => {
      if(searchPermission('Ver calendario de citas a domicilio')){
        await getMeetings(3); 
      }
      else if(searchPermission('Ver calendario de citas de inspección')){
        await getMeetings(1); 
      }
      else(searchPermission('Ver calendario de citas de venta'))
      {
        await getMeetings(2); 
      }
      
      //addSelecttoToolbar();
      if(searchPermission('Configurar agenda de agencia')){
        addIconToBtnConfigurarAgenda(".fc-btnConfigurarAgenda-button","Configurar agenda", "media/icons/iconsax/configuration.svg");
      }
      if(searchPermission('Configurar agenda a domicilio')){
        addIconToBtnConfigurarAgenda(".fc-btnCrearConfigAgendaDomicilio-button","Configurar agenda", "media/icons/iconsax/configuration.svg");
      }
    });


    const getMeetingSelect = computed((value:number) => {
      return value;
    });


    function addSelecttoToolbar(){
      let groupForSelect = document.querySelector(".fc-toolbar-chunk");
      groupForSelect?.append(
        parseHTML(
          `<select class="form-select" id="typeschedule" onChange="getMeetings(this.value)">
            <option value='0' disabled selected>Cita</option>
            <option value='1'>Avalúos</option>
            <option value='2'>Visita</option>
          </select>`
        )
      );
    }

    function addCustomButtons(){
      buttonsCustom.value="";
      /* if(searchPermission('Configurar agenda de agencia')){
        if(buttonsCustom.value!=""){
          buttonsCustom.value+=" btnConfigurarAgenda";
        }else{
          buttonsCustom.value+="btnConfigurarAgenda";
        }
      }
      if(searchPermission('Crear cita')){
        if(buttonsCustom.value!=""){
          buttonsCustom.value+=" btnCrearCita";
        }else{
          buttonsCustom.value+="btnCrearCita";
        }
      }
      if(searchPermission('Configurar agenda a domicilio')){
        if(buttonsCustom.value!=""){
          buttonsCustom.value+=" btnCrearConfigAgendaDomicilio";
        }else{
          buttonsCustom.value+="btnCrearConfigAgendaDomicilio";
        }
      } */
    }

    function createConfigCalendar(){
      addCustomButtons();
      /**
       * Configuration for calendar 
      */     
      calendarOptions.value = {
          locale: esLocale,
          timeZone: "America/Mexico_City",
          plugins: [
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin,
            momentTimezonePlugin,
          ],
          customButtons: {
            /* btnConfigurarAgenda: {
              click: function () {
                ModalConfigSchedules.value!.openModal();
              },
            },
            btnCrearCita: {
              text: "Crear Cita",
              click: function () {
                ModalCreateSchedules.value!.openModal();
              },
            },
            btnCrearConfigAgendaDomicilio: {
              click: function () {
                router.push({name: "ConfigDates",});
              },
            }, */
          },
          headerToolbar: {
            left: "dayGridMonth,timeGridWeek,timeGridDay",
            center: "prev,title,next",
            right: buttonsCustom.value,
          },
          initialDate: TODAY,
          navLinks: true, // can click day/week names to navigate views
          selectable: true,
          selectMirror: true,
          views: {
            dayGridMonth: {
              buttonText: "Mensual",
            },
            timeGridWeek: {
              buttonText: "Semanal",
              eventMinHeight:50
            },
            timeGridDay: {
              buttonText: "Día",
              eventMinHeight:50
            },
          },

          editable: true,
          dayMaxEvents: true, // allow "more" link when too many events
          events: calendarData.value,
          eventClick: openDetail,
          expandRows:true,
      };   
    }
    createConfigCalendar();

    function addIconToBtnConfigurarAgenda(selector:string,text:string,url:string){
      let element = document.querySelector(selector);
      //Configurar agenda media/icons/iconsax/configuration.svg
      element?.append(
        parseHTML(
          `<span>
            <img src="${url}" 
            class="me-3"
            />${text}
          </span>`
        )
      );
    }

    function searchPermission(permission:string){
      return permissions.includes(permission);
    } 

    watch(
      () => route.params.id,
      async newId => {
        idAgency.value = newId;
        getMeetings();
      }
    )

    async function getMeetings(typeSchedule?:number){
      loading.value=true;
      
      if(tipoCita.value != undefined && tipoCita.value != ""){
        typeSchedule = tipoCita.value;
      }
      else{
        tipoCita.value = typeSchedule;
      }
      
      type_schedule.value = typeSchedule;

      //const agencia:any=localStorage.getItem('agencyIdSelected');
      const { data } = await axios.get(process.env.VUE_APP_API_URL + "/api/get-current-agency");
      let agencia = 0;
      if(data.data.id != undefined && data.data.id != null){
        agencia = data.data.id;
      }
      let userInfoObj = localStorage.getItem('userinfo');
      let userInfo = JSON.parse(String(userInfoObj));
      
      const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().clone().startOf('month').add(6, 'M').format('YYYY-MM-DD');
      const datesbeetwen= new FormData();
      
      datesbeetwen.append('fecha_inicial', startOfMonth);
      datesbeetwen.append('fecha_final', endOfMonth);
      
      let tipoRol = '';
      
      await axios.get(process.env.VUE_APP_API_URL + "/api/auth/user").then((response) => {
        // console.log(response);
        
        if (response.status == 200) {
          tipoRol = response.data.data[0].type;
        }
      });

      let url = '';
      // console.log(userInfo);

      if(tipoRol == 'Ciudad' || tipoRol == 'Corporativo'){
        url = `api/citas/detailed-calendar-city/3/${userInfo.city}`; 
      }else{
        // (typeSchedule == "Filtrar citas")? typeSchedule = 1 : false ;
        let type:any = (typeSchedule != undefined && typeSchedule > 0)? typeSchedule: 1 ;
        url= `api/citas/detailed-calendar/${type}/${agencia}`;
        if(idAgency.value != ""  && idAgency.value != undefined && tipoRol == "Múltiples agencias"){
          url= `api/citas/detailed-calendar/${type}/${idAgency.value}`;
        }
      }
      

      ApiService.post(url,datesbeetwen).then((result) => {

        if (result.data.success) {
          let response = result.data.data;
          formatDataCalendar(response);
        }
      })
      .catch(({ response }) => {
        loading.value=false;
      });
    }

    async function formatDataCalendar(response:any){
      var bgMeeting="";
      calendarData.value.splice(0);
      Object.entries(response).forEach((date:any) => {
        Object.values(date[1]).forEach((schedule:any) => {
          if(schedule.citas.length>0){
            Object.values(schedule.citas).forEach((meeting:any) => {
              meeting['date']=date[0];
              meeting['hora']=schedule.hora;
              meetings.value.push(meeting);
              if(meeting.status.name.toLowerCase() == "pendiente"){
                bgMeeting="bg-primary border-primary text-white";
              }
              if(meeting.status.name.toLowerCase() == "cancelada"){
                bgMeeting="bg-danger border-danger text-white";
              }
              if(meeting.status.name.toLowerCase() == "atendida"){
                bgMeeting="bg-success border-success text-white";
              }
              calendarData.value.push({
                title:meeting.name + " " +meeting.last_name,
                start:date[0] +"T"+ schedule.hora,
                description:"",
                date:date[0],
                idmeeting:meeting.id,
                className:bgMeeting,
              }); 
            });
          }
        });
      });
      calendarOptions.value['events']=calendarData.value;
      loading.value=false;
    }

    const configDates = () => {
      router.push({name: "ConfigDates"});
    };

    const createDate = () => {
      getMeetings(type_schedule.value);
      change.value = !change.value;
      ModalCreateSchedules.value!.openModal();
      
    };

    const configAgenda = () => {
      ModalConfigSchedules.value!.openModal();
      
    }

    return {
      type,
      ModalConfigSchedules,
      ModalCreateSchedules,
      ModalDetailMeetingRef,
      searchPermission,
      calendarOptions,
      detailmeeting,
      getMeetings,
      getMeetingSelect,
      tipoCita,
      configDates,
      createDate,
      configAgenda,
      loading,
      type_schedule,
      types,
      route,
      idAgency,
      change,
    };
  },
  methods:{
    
  }
});
