
import { defineComponent, onMounted, ref, computed, watch, toRefs } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { hideModal, showModal } from "@/core/helpers/dom";
import { getIllustrationsPath } from "@/core/helpers/assets";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import loaderClientVue from "@/views/client/components/loader-client.vue";
import { useRoute, useRouter } from "vue-router";
import Datepicker from '@vuepic/vue-datepicker';
import axios from "axios";


interface Step1 {
  typeSchedule: number;
  day: string;
  time: string;
  time_backup: string,
  appraiser: string;
}

interface Step2 {
  firtsname: string;
  lastname: string;
  email: string;
  phone: string;
}

interface Step3 {
  vehicle: string;
  brand: { id: string; name: string };
  model: { id: string; name: string };
  year: { id: string; name: string };
  version: { id: string; name: string };
  owners: number;
  km: string;
}
interface brand {
  id: string;
  name: string;
}
interface model {
  id: string;
  name: string;
}
interface year {
  id: string;
  name: string;
}
interface version {
  id: string;
  name: string;
}

interface KTCreateApp extends Step1, Step2, Step3 { }

export default defineComponent({
  name: "ModalCreateSchedule",
  props: {
    change: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Field,
    Datepicker,
    ErrorMessage,
    loaderClientVue,
  },
  emits: ["updateCalendar", "updateForm", "loadmeetings"],
  setup(props, { emit }) {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAppRef = ref<HTMLElement | null>(null);
    const createAppModalRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const router = useRouter();
    let change = toRefs(props).change;
    /** Mis referencias */
    const ModalCreateScheduleRef = ref<HTMLElement | null>(null);
    const loading = ref(false);
    const typesSchedules = [
      { id: 2, label: "Venta", active: true },
      { id: 1, label: "Avalúo", active: false },
    ];
    const SchedulesDates = ref([]);
    const ScheduleTimes = ref([]);
    const vehiclesOp = ref([]);
    const appraiserOp = ref([]);
    const listBrands = ref<brand[]>([]);
    const listModels = ref<model[]>([]);
    const listYears = ref<year[]>([]);
    const listVersions = ref<version[]>([]);
    const brandTemp = ref<any>("");
    const modelTemp = ref<any>("");
    const yearTemp = ref<any>("");
    const versionTemp = ref<any>("");
    const testNumber = null;
    const bypass = ref<boolean>(false);
    let has_back = ref<number>(0);
    const last_date = ref<any>("");

    const formData = ref<KTCreateApp>({
      typeSchedule: 2,
      day: "",
      time: "",
      time_backup: "",
      appraiser: "",
      firtsname: "",
      lastname: "",
      email: "",
      phone: "",
      vehicle: "",
      brand: { id: "", name: "Selecciona una marca" },
      model: { id: "", name: "" },
      year: { id: "", name: "" },
      version: { id: "", name: "" },
      owners: 1,
      km: "",
    });

    const formatNumber = (value) => {
      let val = (parseInt(value) / 1).toFixed(0);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const addKm = () => {
      let mileage = formData.value.km;
      mileage = formData.value.km.replace(" ", "");
      mileage = formData.value.km.replace("K", "");
      mileage = formData.value.km.replace("M", "");
      mileage = mileage.replace(/,/g, ""),
      mileage = formatNumber(mileage);
      mileage = mileage + " KM";

      formData.value.km = mileage;
    };

    watch(change, async(first, second) => {
      await getScheduleDates({ typeSchedule: 2 });
    });

    watch(formData.value, async(first, second) => {
      console.log(first.day, second.day);
    });

    onMounted(async () => {
      _stepperObj.value = StepperComponent.createInsance(
        createAppRef.value as HTMLElement
      );

      formData.value = {
        typeSchedule: 2,
        day: "",
        time: "",
        time_backup: "",
        appraiser: "",
        firtsname: "",
        lastname: "",
        email: "",
        phone: "",
        vehicle: "",
        brand: { id: "", name: "Selecciona una marca" },
        model: { id: "", name: "Selecciona un modelo" },
        year: { id: "", name: "Selecciona un año" },
        version: { id: "", name: "Selecciona una version" },
        owners: 1,
        km: "",
      };

      let elements = document.getElementsByClassName("dp__clear_icon");
      
      for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener('click', myFunction, false);
      }
      
      await getScheduleDates({ typeSchedule: 2 });
      getAppraiser();
    });


    const myFunction = function() {
        console.log("llego");
    };

    const regexPhone = /^[1-9]\d{2}-\d{3}-\d{4}?$/;
    const createAppSchema = [
      Yup.object().shape({
        typeSchedule: Yup.number()
          .positive("Debes de seleccionar un tipo de cita")
          .required("Debes de seleccionar un tipo de cita")
          .label("Tipo de Cita"),
        day: Yup.string()
          .typeError("Es obligatorio de seleccionar un día")
          .required("Es obligatorio de seleccionar un día")
          .label("Día"),
        time: Yup.string()
          .typeError("Es obligatorio de seleccionar un día")
          .required("Es obligatorio de seleccionar un día")
          .notOneOf([""], "El campo horario es obligatorio")
          .label("Día"),
        appraiser: Yup.mixed().when("typeSchedule", {
          is: (typeSchedule) => {
            return typeSchedule === 1;
          },
          then: Yup.string()
            .typeError("Debes de seleccionar un valuador")
            .required("Debes de seleccionar un valuador")
            .label("Valuador"),
        }),
      }),

      Yup.object({
        firtsname: Yup.string()
          .required("El campo nombre es obligatorio")
          .label("nombres"),
        lastname: Yup.string()
          .required("El campo apellidos es obligatorio")
          .label("apellidos"),
        email: Yup.string()
          .required("El campo email es obligatorio")
          .email("Ingresa un email valido")
          .label("email"),
        phone: Yup.string()
          .matches(regexPhone, "El teléfono es incorrecto")
          .required("El campo teléfono es obligatorio")
          .label("teléfono"),
      }),

      Yup.object({
        vehicle: Yup.string().when("typeSchedule", {
          is: (typeSchedule) => {
            return typeSchedule === 2;
          },
          then: Yup.string()
            .typeError("El campo vehiculo es obligatorio")
            .required("El campo vehiculo es obligatorio")
            .label("Vehiculo"),
          otherwise: Yup.string(),
        }),
        brand: Yup.mixed().when("typeSchedule", {
          is: (typeSchedule) => {
            return typeSchedule === 1;
          },
          then: Yup.string()
            .typeError("Debes de seleccionar una marca")
            .required("Debes de seleccionar una marca")
            .notOneOf(["Selecciona una marca"], "Debes seleccionar una marca")
            .label("Marca"),
          otherwise: Yup.mixed(),
        }),
        model: Yup.mixed().when("typeSchedule", {
          is: (typeSchedule, brand) => {
            return typeSchedule === 1;
          },
          then: Yup.string()
            .typeError("Debes de seleccionar un modelo")
            .required("Debes de seleccionar un modelo")
            .label("Modelo"),
          otherwise: Yup.mixed(),
        }),
        year: Yup.mixed().when("typeSchedule", {
          is: (typeSchedule, model) => {
            return typeSchedule === 1;
          },
          then: Yup.string()
            .typeError("Debes de seleccionar un año")
            .required("Debes de seleccionar un año")
            .label("Año"),
          otherwise: Yup.mixed(),
        }),
        version: Yup.mixed().when("typeSchedule", {
          is: (typeSchedule, year) => {
            return typeSchedule === 1;
          },
          then: Yup.string()
            .typeError("Debes de seleccionar una version")
            .required("Debes de seleccionar una version")
            .label("Version"),
          otherwise: Yup.mixed(),
        }),
        owners: Yup.number().when("typeSchedule", {
          is: (typeSchedule) => {
            return typeSchedule === 1;
          },
          then: Yup.number()
            .positive("Debes de agregar la cantidad de dueños")
            .required("Debes de agregar la cantidad de dueños")
            .label("Dueños"),
          otherwise: Yup.number(),
        }),
        km: Yup.string().when("typeSchedule", {
          is: (typeSchedule) => {
            return typeSchedule === 1;
          },
          then: Yup.string().required("Debes de agregar la cantidad de km").label("KM"),
          otherwise: Yup.string(),
        }),
      }),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit, setTouched } = useForm<Step1 | Step2 | Step3>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (currentStepIndex.value == 2) {
        if (typeof formData.value.brand.id !== "object") {
          formData.value.brand.id != '' ? brandTemp.value = formData.value.brand.id : brandTemp.value = '';
        }
        if (typeof formData.value.model.id !== "object") {
          formData.value.model.id != '' ? modelTemp.value = formData.value.model.id : modelTemp.value = '';
        }
        if (typeof formData.value.year.id !== "object") {
          formData.value.year.id != '' ? yearTemp.value = formData.value.year.id : yearTemp.value = '';
        }
        if (typeof formData.value.version.id !== "object") {
          formData.value.version.id != '' ? versionTemp.value = formData.value.version.id : versionTemp.value = '';
        }
        if (formData.value.time == "") {
          formData.value.time = formData.value.time_backup;
        }
      }

      if (!_stepperObj.value) {
        return;
      }

      // console.log(currentStepIndex.value);
      currentStepIndex.value--;

      (currentStepIndex.value < 0) ? currentStepIndex.value = 0 : false;

      _stepperObj.value.goPrev();
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const confirmTosave = () => {
      handleStep();
    };

    const handleStep = handleSubmit(async (values) => {
      if (formData.value.time == "") {
        formData.value.time = formData.value.time_backup;
      }

      resetForm({
        values: {
          ...formData.value
        },
      });

      setTouched({});

      if (!_stepperObj.value) {
        return;
      }

      if (currentStepIndex.value < _stepperObj.value.totatStepsNumber) {
        // has_back.value++;
        currentStepIndex.value++;
        (currentStepIndex.value > 3) ? currentStepIndex.value = 3 : false;
      }

      if (currentStepIndex.value === _stepperObj.value.totatStepsNumber) {
        await formSubmit();
      } else {
        // console.log("pervpaso", currentStepIndex.value)
        if (currentStepIndex.value == 2) {
          brandTemp.value != "" ? setBrand(true) : "";
          modelTemp.value != "" ? setModel(true) : "";
          yearTemp.value != "" ? setYear(true) : "";
          versionTemp.value != "" ? setVersion(true) : "";
        }

        _stepperObj.value.goNext();
      }
    });

    const formSubmit = async () => {
      loading.value = true;
      let data = {};

      if (formData.value.time == "") {
        formData.value.time = formData.value.time_backup;
      }

      //AVALUO EN AGENCIA
      if (formData.value.typeSchedule == 1) {
        let mileage = formData.value.km;
          mileage = formData.value.km.replace(" KM", "");
          mileage = mileage.replace(/,/g, "");
        
        data = {
            tipo: formData.value.typeSchedule,
            valuador: formData.value.appraiser,
            fecha: formData.value.time,
            nombre: formData.value.firtsname,
            apellidos: formData.value.lastname,
            email: formData.value.email,
            phone: formData.value.phone,
            marca: formData.value.brand,
            modelo: formData.value.model,
            year: formData.value.year,
            version: formData.value.version,
            mileage: mileage,
            owners: formData.value.owners,
          };
      }

      //VENTA
      if (formData.value.typeSchedule == 2) {
        data = {
          tipo: formData.value.typeSchedule,
          fecha: formData.value.time,
          nombre: formData.value.firtsname,
          apellidos: formData.value.lastname,
          email: formData.value.email,
          phone: formData.value.phone,
          vehicle: formData.value.vehicle,
        };
      }

      ApiService.post("api/citas", data)
        .then((result) => {
          if (result.data.success) {
            loading.value = false;

            currentStepIndex.value = 1;
            previousStep();

            emit("updateCalendar", formData.value.typeSchedule);

            formData.value = {
              typeSchedule: 2,
              day: "",
              time: "",
              time_backup: "",
              appraiser: "",
              firtsname: "",
              lastname: "",
              email: "",
              phone: "",
              vehicle: "",
              brand: { id: "", name: "Selecciona una marca" },
              model: { id: "", name: "Selecciona un modelo" },
              version: { id: "", name: "Selecciona una versión" },
              year: { id: "", name: "Selecciona un año" },
              owners: 1,
              km: "",
            };

            brandTemp.value = ""
            modelTemp.value = ""
            yearTemp.value = ""
            versionTemp.value = ""

            resetForm({
              values: {
                ...formData.value,
              },
            });

            setTouched({});

            currentStepIndex.value = 0;
            previousStep();

            Swal.fire({
              html:
                '<p class="fw-bold text-white">Cita creada</p><p>Se ha agendado de manera correcta la cita</p>',
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Cerrar",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              closeModal();

              // router.go(0);
            });
          }
        })
        .catch((error: any) => {
          loading.value = false;
          console.log('error', currentStepIndex.value);
          currentStepIndex.value = 2;
          // previousStep();

          if (typeof error.success !== "undefined" && error.success == false) {
            Swal.fire({
              text: error.message,
              icon: "error",
            }).then(() => {
              console.log("error");
            });
          } else {
            Swal.fire({
              text:
                typeof error.response.data.errors === "object"
                  ? Object.values(error.response.data.errors)
                    .toString()
                    .replace(",", "</br>")
                  : error.response.data.message,
              icon: "error",
            }).then(() => {
              console.log("error");
            });
          }
        });
    };

    const openModal = () => {
      showModal(ModalCreateScheduleRef.value);
    };
    
    const closeModal = () => {
      formData.value = {
        typeSchedule: 2,
        day: "",
        time: "",
        time_backup: "",
        appraiser: "",
        firtsname: "",
        lastname: "",
        email: "",
        phone: "",
        vehicle: "",
        brand: { id: "", name: "Selecciona una marca" },
        model: { id: "", name: "Selecciona un modelo" },
        year: { id: "", name: "Selecciona un año" },
        version: { id: "", name: "Selecciona una versión" },
        owners: 1,
        km: "",
      };

      resetForm({
        values: {
          ...formData.value,
        },
      });

      resetForm();

      if (currentStepIndex.value == 3) {
        previousStep();
      }

      if (currentStepIndex.value == 2) {
        previousStep();
      }

      if (currentStepIndex.value == 1) {
        previousStep();
      }

      currentStepIndex.value = 0;
      previousStep();

      hideModal(ModalCreateScheduleRef.value);
    };

    const disabledDate = (time: Date) => {
      if (Object.keys(SchedulesDates).length > 0) {
        return typeof SchedulesDates.value[moment(time).format("YYYY-MM-DD")] !==
          "undefined"
          ? false
          : true;
      }
      return true;
    };

    async function onChangeTypeSchedule(typeSchedule: number) {
      SchedulesDates.value = [];
      ScheduleTimes.value = [];
      formData.value.day = "";
      formData.value.time = "";

      await getScheduleDates({ typeSchedule: typeSchedule });
      if (typeSchedule === 1) {
        await getBrands();
      }

      formData.value.typeSchedule = typeSchedule;
      resetForm();
    }

    /**
     * Función para actualizar el día y buscar los horarios disponibles
     */
    function onChangeDay(date) {
      if (date != null && date != "") {
        // console.log("last_date.value", last_date.value);
        // console.log("date", date);

        if(last_date.value != moment(date).format("YYYY-MM-DD")){
          last_date.value = moment(date).format("YYYY-MM-DD");

          //formData.value.day = "";
          //formData.value.day = day;  
          formData.value.time = "";
          ScheduleTimes.value = [];
          
          // console.log("Change day");
          // console.log(SchedulesDates.value);
          
          if (Object.keys(SchedulesDates).length > 0) {
            if (typeof SchedulesDates.value[moment(date).format("YYYY-MM-DD")] !== "undefined") {
              ScheduleTimes.value = SchedulesDates.value[moment(date).format("YYYY-MM-DD")];
            }
          }
        }
      }
    }

    const getScheduleDates = async ({ typeSchedule = 2 }: { typeSchedule?: number }) => {
      loading.value = true;
      //const agencia: any = localStorage.getItem("agencyIdSelected");
      const { data } = await axios.get(process.env.VUE_APP_API_URL + "/api/get-current-agency");
      let currencyAgencyId = 0;
      if(data.data.id != undefined && data.data.id != null){
        currencyAgencyId = data.data.id;
      }

      const startOfMonth = moment().clone().startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment()
        .clone()
        .startOf("month")
        .add(6, "M")
        .format("YYYY-MM-DD");
      const datesbeetwen = new FormData();
      datesbeetwen.append("fecha_inicial", startOfMonth);
      datesbeetwen.append("fecha_final", endOfMonth);
      
      const url = "api/citas/calendar/" + typeSchedule + "/" + currencyAgencyId;
      
      ApiService.post(url, datesbeetwen)
        .then((result) => {
          //console.log("Llego aqui!!",result);

          if (result.data.success) {

            let response = result.data.data;
            SchedulesDates.value = response;
            loading.value = false;
          }
        })
        .catch(({ response }) => {
          loading.value = false;
        });
      resetForm({
        values: {
          ...formData.value,
        },
      });
    };

    function searchVehicle(query: string) {
      if (query) {
        loading.value = true;
        ApiService.get("/api/vehicles?status=2&buscar=" + query)
          .then((result) => {
            if (result.data.success) {
              let response = result.data.data.data;
              vehiclesOp.value = response;
              loading.value = false;
            }
          })
          .catch(({ response }) => {
            loading.value = false;
          });
        resetForm();
      } else {
        vehiclesOp.value = [];
      }
    }

    function getAppraiser() {
      const agencia: any = localStorage.getItem("agencyIdSelected");
      ApiService.get("api/users?idRole=3&idAgency=" + agencia)
        .then((result) => {
          //console.log(result);
          if (result.data.success) {
            let response = result.data.data.data;
            appraiserOp.value = response.users;
          }
        })
        .catch(({ response }) => {
          appraiserOp.value = [];
          console.log(response);
        });
    }

    /**
     * Métodos para vehiculos
     */
    const getBrands = async () => {
      loading.value = true;
      let url = "/api/maxi/brands/v_1";
      // console.log("Llego");
      try {
        const { data } = await ApiService.get(url);
        listBrands.value = data.data;

      } catch (error) {
        console.log(error);
      }

      loading.value = false;
    };

    const getModels = async () => {
      loading.value = true;
      listModels.value = [];
      listYears.value = [];
      listVersions.value = [];

      formData.value.model = { id: "", name: "" };
      formData.value.year = { id: "", name: "" };
      formData.value.version = { id: "", name: "" };

      try {
        const { data } = await ApiService.get(`/api/maxi/models/${formData.value.brand.id}`);
        listModels.value = data.data;

      } catch (error) {
        console.log(error);
        listModels.value = [];
      }

      loading.value = false;
    };

    const getYears = async () => {
      loading.value = true;
      listYears.value = [];
      listVersions.value = [];
      formData.value.year = { id: "", name: "" };
      formData.value.version = { id: "", name: "" };
      const model: any = listModels.value.find((m) => m.id == formData.value.model.id);
      formData.value.model = model;

      try {
        const { data } = await ApiService.get(`/api/maxi/years/${formData.value.model.id}`);
        listYears.value = data.data;
      } catch (error) {
        console.log(error);
      }

      loading.value = false;
    };

    const getVersions = async () => {
      listVersions.value = [];
      formData.value.version = { id: "", name: "" };

      if (formData.value.brand.id && formData.value.model.id && formData.value.year.id) {
        loading.value = true;

        try {
          const { data } = await ApiService.get(`/api/maxi/versions/${formData.value.year.id}`);
          listVersions.value = data.data;
        } catch (error) {
          console.log(error);
        }

        loading.value = false;
      }

    };

    const setBrand = async (goAndCome = false) => {

      await getBrands();
      let timeout = false
      if (currentStepIndex.value == 2 && goAndCome) {
        const brand: any = listBrands.value.find(
          (v) => {
            timeout = true
            return v.id == brandTemp.value
          }
        )
        if (timeout) formData.value.brand = brand;

      } else if (!goAndCome) {
        const brand: any = listBrands.value.find(
          (v) => {
            timeout = true
            return v.id == formData.value.brand.id;
          })
        brandTemp.value = formData.value.brand.id;
        if (timeout) formData.value.brand = brand;
      }
    };

    const setModel = async (goAndCome = false) => {
      try {
        console.log("brand", formData.value.brand.id);
        console.log("modeltem", modelTemp.value);
        console.log("brand", formData.value.model.id);
        const { data } = await ApiService.get(`/api/maxi/models/${typeof formData.value.brand.id === "object" ? brandTemp.value : formData.value.brand.id}`);
        listModels.value = data.data;

      } catch (error) {
        console.log(error);
        listModels.value = [];
      }
      let timeout = false
      if (currentStepIndex.value == 2 && goAndCome) {
        const model: any = listModels.value.find(
          (v) => {
            timeout = true
            return v.id == modelTemp.value
          }
        )
        if (timeout) formData.value.model = model;

      } else if (!goAndCome) {
        const model: any = listModels.value.find(
          (v) => {
            timeout = true
            return v.id == formData.value.model.id;
          }
        )
        if (timeout) formData.value.model = model;
        modelTemp.value = formData.value.model.id;
      }

    };

    const setYear = async (goAndCome = false) => {
      // await getYears();

      try {
        const { data } = await ApiService.get(`/api/maxi/years/${typeof formData.value.model.id === "object" ? modelTemp.value : formData.value.model.id}`);
        listYears.value = data.data;
      } catch (error) {
        console.log(error);
      }
      let timeout = false
      if (currentStepIndex.value == 2 && goAndCome) {

        const year: any = listYears.value.find(
          (v) => {
            timeout = true
            return v.id == yearTemp.value
          }
        )
        if (timeout) formData.value.year = year;

      } else if (!goAndCome) {
        const year: any = listYears.value.find(
          (v) => {
            timeout = true
            return v.id == formData.value.year.id
          }
        )
        if (timeout) formData.value.year = year;
        yearTemp.value = formData.value.year.id;
      }

    };

    const setVersion = async (goAndCome = false) => {
      // await getVersions();
      if (formData.value.brand.id && formData.value.model.id && formData.value.year.id) {
        try {
          const { data } = await ApiService.get(`/api/maxi/versions/${typeof formData.value.year.id === "object" ? yearTemp.value : formData.value.year.id}`);
          listVersions.value = data.data;
        } catch (error) {
          console.log(error);
        }

        let timeout = false

        if (currentStepIndex.value == 2 && goAndCome) {

          const version: any = listVersions.value.find(
            (v) => {
              timeout = true
              return v.id == versionTemp.value;
            }
          )
          if (timeout) formData.value.version = version;

        } else if (!goAndCome) {
          const version: any = listVersions.value.find(
            (v) => {
              timeout = true
              return v.id == formData.value.version.id;
            }
          )
          if (timeout) formData.value.version = version;
          versionTemp.value = formData.value.version.id;
        }
      }
    };

    return {
      handleStep,
      formSubmit,
      previousStep,
      createAppRef,
      currentStepIndex,
      totalSteps,
      createAppModalRef,
      getIllustrationsPath,
      loading,
      openModal,
      closeModal,
      ModalCreateScheduleRef,
      disabledDate,
      onChangeTypeSchedule,
      onChangeDay,
      formData,
      ScheduleTimes,
      typesSchedules,
      vehiclesOp,
      appraiserOp,
      searchVehicle,
      listBrands,
      listModels,
      listYears,
      listVersions,

      getBrands,
      getModels,
      getYears,
      getVersions,

      setBrand,
      setModel,
      setYear,
      setVersion,

      addKm,
      testNumber,
      confirmTosave,
      bypass,
      has_back,
      brandTemp,
      modelTemp,
      yearTemp,
      versionTemp
    };
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatKm(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " KM";
    },
  },
});
