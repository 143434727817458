
import { defineComponent, ref, toRefs, watch } from "vue";
import { hideModal, showModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import loaderClientVue from "@/views/client/components/loader-client.vue";
import { useRoute, useRouter } from "vue-router";
interface Schedule {
  id: string | null;
  comment: string;
  inspection: boolean | null;
  file: any;
  status: number;
}
export default defineComponent({
  name: "citaDetalleVehicle",
  components: {
    Field,
    Form,
    ErrorMessage,
    loaderClientVue,
  },
  props: {
    showModal: {
      required: true,
      type: Boolean,
      default: false,
    },
    idschedule: {
      type: String,
      default: "",
    },
    idtypemeeting: {
      type: String,
      default: "",
    },
  },
  emits: ["closeAtendida", "closeSelect", "updateCalendar"],
  setup(props, { emit }) {
    const files = ref<any>([]);
    const filediagnostic = ref();
    const detalleCitaModalRef = ref<null | HTMLElement>(null);
    const showModalProp = toRefs(props).showModal;
    const isEdit = ref<boolean>(false);
    const inputFile = ref();
    const imagesThum = ref<any>([]);
    const fileload = ref(false);
    const loading = ref<boolean>(false);
    const typemeeting: any = toRefs(props).idtypemeeting;

    const closeAtendida = () => {
      formData.value = {
        id: props.idschedule,
        comment: "",
        inspection: null,
        file: "",
        status: 2,
      };
      emit("closeAtendida", false);
      emit("closeSelect", false);
    };

    const router = useRouter();

    const formData = ref<Schedule>({
      id: props.idschedule,
      comment: "",
      inspection: null,
      file: "",
      status: 2,
    });

    watch(showModalProp, async (current) => {
      if (current) {
        showModal(detalleCitaModalRef.value);
      }
    });

    const dropEvent = (evt) => {
      const file = evt.dataTransfer.files[0];
      let errorFiles = false;
      const typesFiles = ["image/jpeg", "image/png", "application/pdf"];
      if (typesFiles.includes(file.type)) {
        let tamanio = file.size / 1024 / 1024;
        if (tamanio > 10) {
          inputFile.value = "";
          filediagnostic.value = "";
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Solo se pueden subir archivos inferiores a 10 MB",
            showConfirmButton: true,
          });
        } else {
          fileload.value = true;
          formData.value.file = file;
        }
      } else {
        inputFile.value = "";
        filediagnostic.value = "";
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            "Algunos archivos no fueron cargados ya que no tienen el formato esperado",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    };

    const getFilesInput = () => {
      const file = inputFile.value.files[0];
      let errorFiles = false;
      const typesFiles = ["image/jpeg", "image/png", "application/pdf"];
      if (typesFiles.includes(file.type)) {
        let tamanio = file.size / 1024 / 1024;
        if (tamanio > 10) {
          inputFile.value = "";
          filediagnostic.value = "";
          fileload.value = false;
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Solo se pueden subir archivos inferiores a 10 MB",
            showConfirmButton: true,
          });
        } else {
          fileload.value = true;
          formData.value.file = file;
        }
      } else {
        inputFile.value = "";
        filediagnostic.value = "";
        fileload.value = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            "Algunos archivos no fueron cargados ya que no tienen el formato esperado",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    };

    const checkData = (evt) => {
      evt.preventDefault();
    };

    const agregarImagenes = () => {
      inputFile.value.click();
    };

    const eliminarImagen = async (index) => {
      inputFile.value = "";
      fileload.value = false;
      formData.value.file = "";
    };

    const validation = Yup.object({
      comment: Yup.string()
        .required("El campo comentario es obligatorio")
        .label("Comentario"),
      inspection: Yup.mixed().when({
        is: () => {
          let result = false;
          if (formData.value.inspection === null) {
            result = true;
          }
          return result;
        },
        then: Yup.mixed()
          .required("Se requiere seleccionar una opción")
          .label("Comentario"),
        otherwise: Yup.mixed(),
      }),
      inputFile: Yup.mixed().when({
        is: () => {
          let result = false;
          if (formData.value.inspection === true && formData.value.file == "") {
            result = true;
          }
          return result;
        },
        then: Yup.mixed().required("El archivo es obligatorio").label("Comentario"),
        otherwise: Yup.mixed(),
      }),
    });

    const sendData = () => {
      const data: any = new FormData();
      data.append("comentarios", formData.value.comment);
      // console.log(formData.value.inspection);

      if (typemeeting.value == 1 || typemeeting.value == 3) {
        data.append("realizo_inspeccion", formData.value.inspection ? "Si" : "No");
      } else {
        data.append("realizo_inspeccion", "No");
      }
      
      if (formData.value.file != "") {
        data.append("documento", formData.value.file);
      }
      
      ApiService.post("api/citas/attended/" + props.idschedule, data)
        .then((result) => {
          if (result.data.success) {
            loading.value = false;
            
            Swal.fire({
              text: "Se actualizó correctamente el estatus de la cita.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Cerrar",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              closeAtendida;
              emit("updateCalendar", 2);
              router.go(0);
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Hubo un error al intentar garudar el registro",
              text: result.data.message,
              buttonsStyling: false,
              confirmButtonText: "Cerrar",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          }
        }).catch((error) => {
          loading.value = false;
          if (typeof error.success !== "undefined" && error.success == false) {
            Swal.fire({
              text:
                typeof error.errors === "object"
                  ? Object.values(error.errors).toString().replace(",", "</br>")
                  : error.message,
              icon: "error",
              title: "Hubo un error, en el servidor al intentar garudar el registro",
            }).then(() => {
              closeAtendida;
            });
          } else {
            Swal.fire({
              text:
                typeof error.response.data.errors === "object"
                  ? Object.values(error.response.data.errors)
                      .toString()
                      .replace(",", "</br>")
                  : error.response.data.message,
              icon: "error",
              title: "Hubo un error, en el servidor al intentar garudar el registro",
            }).then(() => {
              closeAtendida;
            });
          }
        });
    };

    return {
      detalleCitaModalRef,
      closeAtendida,
      dropEvent,
      getFilesInput,
      checkData,
      files,
      isEdit,
      agregarImagenes,
      inputFile,
      fileload,
      filediagnostic,
      eliminarImagen,
      formData,
      sendData,
      validation,
      loading,
      typemeeting,
    };
  },
});
